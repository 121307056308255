<template>

    <AddEditForm :headerTitle="$t('pages.product.addCategory')" :backTitle="$t('menu.backToCategoryPage')" :onGoBack="onGoBack" :onSave="onSave" :onSaveContinue="onSaveContinue" :showDelete="false">
        <template #content>
            <Accordion :headerTitle="$t('common.generalInfo')" v-if="editingCategory != null">
                <template v-slot:body>
                    <CForm>
                        <CInput :label="$t('common.name')" :placeholder="$t('pages.product.enterCategoryName')"
                                v-model="editingCategory.name" :is-valid="validator" required horizontal>
                            <template #invalid-feedback>
                                <div class="invalid-feedback">
                                    {{ $t('pages.product.categoryRequired') }}
                                </div>
                            </template>
                            <!-- <small class="requiredInput">(*)</small>						 -->
                        </CInput>
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('pages.product.categoryParent') }}</label>
                            <div class="col-sm-9 input-group">
                                <select class="form-control" v-model="editingCategory.parentId">
                                    <option selected="selected" value="0">{{ $t('pages.product.selectCategory') }}</option>
                                    <option v-for="(item, idx) in categoryTree" v-bind:key="item.id" v-bind:value="item.id" v-bind:selected="idx == 0">
                                        {{item.hierarchyName}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div v-if="invalidInput" role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label"></label>
                            <div class="col-sm-9 input-group">
                                <div class="requiredInput">{{ errorInput }}</div>
                            </div>
                        </div>
                    </CForm>
                </template>
            </Accordion>


            <Confirmation ref="confirmation"></Confirmation>
        </template>

    </AddEditForm>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import { mapGetters, mapState } from 'vuex'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    import Confirmation from '@/components/Confirmation.vue'

    export default {
        name: 'categoryadd',
        data() {
            return {
                horizontal: { label: 'col-3', input: 'col-9' },
                invalidInput: false,
                errorInput: '',
            }
        },
        components: { AddEditForm, Accordion, Confirmation },
        computed: {
            ...mapGetters('category', ['editingCategory', 'hasError', 'errorMessage']),
            ...mapState('category', ['categoryTree', 'isLoadingCategoryTree'])
        },
        methods: {
            validator(val) {
                return val ? val.length > 0 : false
            },
            onGoBack() {
                this.$router.push('/products/categories');
            },
            onSave() {
                this.addNewCategory(false);
            },
            onSaveContinue() {
                this.addNewCategory(true);
            },
            onCancel() {
                this.$router.push('/products/categories');
            },

            async addNewCategory(bContinueEdit) {
                console.log("Call to ADDNEWCATEGORY");
                if (!this.validator(this.editingCategory.name)) {
                    this.invalidInput = true;
                    this.errorInput = this.$t('pages.product.categoryRequired')
                    return;
                }
                var retVal = await this.$store.dispatch("category/saveCategory");
                if (retVal != null) {
                    if (bContinueEdit) {
                        this.$router.push('/products/editcategory/' + retVal.id);
                    }
                    else {
                        this.onGoBack();
                    }
                }
            },
         
        },
        mounted() {
            this.$store.dispatch("category/initNewCategory");
            this.$store.dispatch("category/getCategoryTree");
        },
    }
</script>